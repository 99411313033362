import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { environment } from './environments/environment'
import { map } from 'rxjs/operators'
import SearchParams from './models/SearchParams'
import BatchParams from './models/BatchParams'
import UserAction from './models/UserAction'
import { Observable } from 'rxjs'
import { RootScopeService } from './app/core/services/root-scope.service'
import { letterIds } from './app/models/email-template/sent-email'

@Injectable({
    providedIn:'root'
})

export class ApiService {
    source: string | undefined
    customer_cd:string
    constructor(private http: HttpClient,private rootScope:RootScopeService) {
        this.source=this.rootScope.globals.source;
        this.customer_cd=this.rootScope.globals.currentCustomer;
    }
    
    // gets the full response from an individual document given an ID
    // used to display the details when the eye icon is clicked on the main page
    public getDocumentData(document_id:string, customer_cd?:string) {
        let url = environment.server.concat(`/details/${document_id}`)
        if (customer_cd != undefined) {
            url = url.concat(`/?customer_cd=${customer_cd}`)
        }
        return this.http.get<any>(url, {observe:'response'}).pipe(
            map((response:any) => {
                return response.body
            }))
    }

    // generates a presigned url for document PDFs. 
    // takes the primary key and sort key as args
    public getPdfPreview(document_id:string, letter_id:string) {
        let url = environment.server.concat(`/document/${document_id}/${letter_id}`)
        return this.http.get<any>(url, {observe:'response'}).pipe(
            map((response:any) => {
                return response.body
            })
        )
    }

    getLetterIds(customer_cd:string): Observable<letterIds[]> {
        let url = environment.server.concat(`/letter-ids/${customer_cd}`)
        return this.http.get<letterIds[]>(url);
      }
      
    public getPdfview(document_id:string) {
        let url = environment.server.concat(`/document/${document_id}`)
        return this.http.get<any>(url, {observe:'response'}).pipe(
            map((response:any) => {
                return response.body
            })
        )
    }


    // hard delete. frontend logic is currently commented out
    // use this endpoint when hard deleting, as DynamoDB's non-relational structure means a delete requires deletion from two tables
    public deleteEntry(document_id:string, letter_id:string, date:string, time:string, batch_id:string, batch_file_name:string) {
        let url = environment.server.concat("/delete")
        url = url.concat(`/${document_id}/${letter_id}/${date}/${time}/${batch_id}/${batch_file_name}`)

        return this.http.get<any>(url, {observe:'response'}).pipe(
            map((response:any) => {
                return response.body
            })
        )
    }

    // part of hard delete logic. frontend logic is commeneted out
    // updates the duplicates array that each entry in the DB has
    public updateDuplicateEntries(document_id:string, document_to_delete:string) {
        let url = environment.server.concat(`/updateDuplicates/${document_id}/${document_to_delete}`)
        return this.http.get<any>(url, {observe:'response'}).pipe(
            map((response:any) => {
                return response.body
            })
        )
    }

    // soft delete logic
    // adds a DB column called "deleted" with a timestamp of the deletion
    // updates the 'reviewed' column to say deleted
    public softDelete(document_id:string, username:string) {
        const deleted_user = username.replace(/ /g, "%20");
        let url = environment.server.concat(`/softDelete?document_id=${document_id}&&deleted_user=${deleted_user}`)

        return this.http.get<any>(url, {observe:'response'}).pipe(
            map((response:any) => {
                return response.body
            })
        )
    }

    public deleteBatch(batch_identifier: string, batch_file_name: string, date_of_upload: string) {
        const url = environment.server.concat(`/deleteBatch`);
        const payload = { batch_identifier, batch_file_name, date_of_upload };
    
        return this.http.post<any>(url, payload, { observe: 'response' }).pipe(
            map((response: any) => {
                return response.body;
            })
        );
    }    

    //Retigger the document payload 
    //pushed the doc payload to SQS
    public retriggerEmail(element: any) {
        const doc_payload = element.doc_payload.replace(/ /g, "%20");
        const sort_key = element.sort_key.replace(/ /g, "%20");
        const customer_cd = element.customer_cd;
    
        // Construct the base URL with required parameters
        let url = environment.server.concat(`/retrigger?sort_key=${sort_key}&customer_cd=${customer_cd}&doc_payload=${doc_payload}`);
    
        // Check if the element includes the field 'referral_email' and it's true
        if (element.referral_email === true) {
            url += '&referral_email=true';
        }
            
        // Check if gb_metadata exists before parsing
        if (element.gb_metadata) {
            let gb_metadata:any = {};
            try {
                gb_metadata = JSON.parse(element.gb_metadata);
                // Iterate over gb_metadata object and append each property as a query parameter
                Object.keys(gb_metadata).forEach(key => {
                    // Use encodeURIComponent for proper encoding of URI components
                    const value = encodeURIComponent(gb_metadata[key].toString());
                    url += `&${key}=${value}`;
                });
            } catch (error) {
                console.error("Error parsing gb_metadata JSON string:", error);
            }
        }
    
        console.log(url);
        return this.http.put<any>(url, {observe: 'response'}).pipe(
            map((response: any) => {
                return response.body;
            })
        );
    }
    
    // main get endpoint for batches
    // url updated based on searchParams
    public getBatches(batch_identifier?:string|null, batch_file_name?:string|null, date_of_upload?:string|null, search_params?:BatchParams, customer_cd?:string) {
        
        let url = environment.server.concat('/batch_search')
        this.source=this.rootScope.globals.source;
        if (batch_identifier != null && batch_file_name != null && date_of_upload != null) {
            url = url.concat(`/?batch_identifier=${batch_identifier}&&batch_file_name=${batch_file_name}&&date_of_upload=${date_of_upload}&&`)
        }

        else {
            url = url.concat("/?")
        }
        if (customer_cd != undefined) {
            url = url.concat(`customer_cd=${customer_cd}&&`)
        }
        if(this.source!=undefined) {
            url=url.concat(`source=${this.source}&&`)
        }
        if (search_params != undefined) {
            if (search_params.batch_file_name != null && search_params.batch_file_name != '') {
                url = url.concat(`batch_file_filter=${search_params.batch_file_name}&&`)
            }

            if (search_params.reviewed != null) {
                url = url.concat(`reviewed=${search_params.reviewed}&&`)
            }

            if (search_params.date_of_upload != null && search_params.date_of_upload != '') {
                url = url.concat(`date_filter=${search_params.date_of_upload}`)
            }
        }

        return this.http.get<any>(url, {observe: 'response'}).pipe(
            map((response:any) => {
                return response.body
            })
        )
    }


    // updates the 'reviewed' column in the backend to either true or false
    public updateReview(document_id:string, date:string, updatedValue:string, batch_id:string, batch_file_name:string) {
        let url = environment.server.concat('/updateReview')
        date = date.replace(/ /g, "%20")

        url = url.concat(`/?document_id=${document_id}&&date=${date}&&updated_value=${updatedValue}&&batch_id=${batch_id}&&batch_file_name=${batch_file_name}`)


        return this.http.put<any>(url, {observe:'response'}).pipe(
            map((response:any) => {
                return response.body
            })
        )
    }

    public logUserAction(actionParams: UserAction) {
        let url = environment.server.concat('/log_action');
        this.source = this.rootScope.globals.source;
    
        actionParams.user = actionParams.user?.replace(/ /g, "%20");
        actionParams.date_of_action = actionParams.date_of_action.replace(/ /g, "%20");
        actionParams.date_of_upload = actionParams.date_of_upload.replace(/ /g, "%20");
    
        // Start building the URL with required parameters
        url = url.concat(`/?name=${actionParams.user}&&date_of_action=${actionParams.date_of_action}&&action=${actionParams.action}&&date_of_upload=${actionParams.date_of_upload}&&document_id=${actionParams.document_id || "undefined"}&&item=${actionParams.item}&&customer_cd=${this.customer_cd}&&original=${actionParams.original}`);
    
        if (this.source != undefined) {
            url = url.concat(`&&source=${this.source}`);
        }
    
        // Include upload_id if it exists
        if (actionParams.upload_id != undefined) {
            url = url.concat(`&&upload_id=${actionParams.upload_id}`);
        } 
    
        // Include batch_id if it exists
        if (actionParams.batch_id != undefined) {
            url = url.concat(`&&batch_id=${actionParams.batch_id}`);
        } 
    
        return this.http.post<any>(url, { observe: 'response' }).pipe(
            map((res: any) => {
                return JSON.stringify(res.body);
            })
        );
    }

    public getUserActions(customer_cd:string, last_seen_user?:string, last_seen_date?:string, action?:string) {
        let url = environment.server.concat(`/getUserActions/?customer_cd=${customer_cd}`)
        this.source=this.rootScope.globals.source;
        if(this.source!=undefined)
            {
               url= url.concat(`&&source=${this.source}&&`)
            }
        if (last_seen_user && last_seen_date && action) {
            last_seen_user = last_seen_user.replace(/ /g, "%20");
            last_seen_date = last_seen_date.replace(/ /g, "%20");
            action = action.replace(/ /g, "%20");
            url = url.concat(`&&last_seen_user=${last_seen_user}&&last_seen_date=${last_seen_date}&&action=${action}`)
        }
        console.log(url)
        return this.http.get<any>(url, {observe:'response'}).pipe(
            map((res:any) => {
                return (res.body)
            })
        )
    }

    public getUnhandledExceptions(customer_cd:string) {
        let url = environment.server.concat('/getUnhandledExceptions')
        if (customer_cd != undefined) {
            url = url.concat(`/?customer_cd=${customer_cd}&&`)
        }
        return this.http.get<any>(url, {observe:'response'}).pipe(
            map((res:any) => {
                return (res.body)
            })
        )
    }

    public updateFailureReview(customer_cd:string, sort_key:string) {
        sort_key = sort_key?.replace(/ /g, "%20")
        let url = environment.server.concat('/updateFailureReview')
        console.log(url)
        url = url.concat(`/${customer_cd}/${sort_key}`)
        return this.http.put<any>(url, {observe:'response'}).pipe(
            map((res:any) => {
                return JSON.stringify(res.body)
            })
        )
    }

    public getEmailSuccessTimestamp(document_id:string) {
        let url = environment.server.concat(`/getSentEmailTimestamp?document_id=${document_id}`)
        return this.http.get<any>(url, {observe:'response'}).pipe(
            map((response:any) => {
                return response.body
            })
        )
    }

    public getEmailFailureTimestamp(doc_payload:string) {
        let url = environment.server.concat(`/getFailedEmailTimestamp?doc_payload=${doc_payload}`)
        return this.http.get<any>(url, {observe: 'response'}).pipe(
            map((response:any) => {
                return response.body
            })
        )
    }

    // main get endpoint for document mode
    // updates url based on given search parameters
    public getEntries(document_id?:string|null, date?:string|null,customer_cd?:string,search_params?:SearchParams) {
        let url = environment.server.concat('/document_search')
        this.source=this.rootScope.globals.source;
        if (search_params?.isSearchingDuplicates == true)  url = environment.server.concat('/get_document_by_document_ids')
        if(search_params?.batch_id) url = environment.server.concat('/get_documents_by_batch_id')
        date = date?.replace(/ /g, "%20")

        if (document_id != null && date != null) {
            url = url.concat(`/?last_seen_id=${document_id}&&last_seen_date=${date}&&`)
        }

        else { 
            url = url.concat("/?")
        }
        if (customer_cd != undefined) {
            url = url.concat(`customer_cd=${customer_cd}&&`)
        }
        if(this.source != undefined)
            {
                url=url.concat(`source=${this.source}&&`)
            }
        if (search_params != undefined) {
            if (search_params.claim_number != null && search_params.claim_number != '') {
                url = url.concat(`claim_number=${search_params.claim_number}&&`)
            }

            if (search_params.first_name != null && search_params.first_name != '') {
                url = url.concat(`first_name=${search_params.first_name}&&`)
            }

            if (search_params.last_name != null && search_params.last_name != '') {
                url = url.concat(`last_name=${search_params.last_name}&&`)
            }

            if (search_params.letter_id != null && search_params.letter_id != '') {
                url = url.concat(`letter_id=${search_params.letter_id}&&`)
            }

            if (search_params.batch_id != null && search_params.batch_id != '') {
                url = url.concat(`batch_id=${search_params.batch_id}&&`)
            }

            if (search_params.date_of_letter != null && search_params.date_of_letter != '') {
                let date_of_letter = search_params.date_of_letter.replace(/ /g, "%20")
                url = url.concat(`date_of_letter=${date_of_letter}&&`)
            }

            if (search_params.start_date != null && search_params.end_date != null) {
                let start_date = search_params.start_date.replace(/ /g, "%20")
                let end_date = search_params.end_date.replace(/ /g, "%20")
                url = url.concat(`start_date=${start_date}&&end_date=${end_date}&&`)
            }

            if (search_params.date_of_upload != null && search_params.date_of_upload != '') {
                url = url.concat(`date_of_upload=${search_params.date_of_upload}`)
            }   

            if (search_params.isSearchingDuplicates == true) {
                url=url.concat(`duplicates=`)
                url = url.concat(`${search_params.duplicates},`)
                /*for (let i = 0; i < search_params.duplicates?.length!; i++) {
                    url = url.concat(`${search_params.duplicates},`)
                }*/
            }
            if (search_params?.batch_id && search_params.document_ids!=null && search_params.document_ids!='')  {
                url=url.concat(`document_ids=`)
                url = url.concat(`${search_params.document_ids}`)
            }

        }
         //console.log(url)
        return this.http.get<any>(url, {observe:'response'}).pipe(
            map((response:any) => {
                //console.log(response.body)
                return response.body
            })
        )
    }
 }