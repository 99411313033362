import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/api.service';
import { RootScopeService } from 'src/app/core/services/root-scope.service';
import SearchParams from 'src/models/SearchParams'
import StatsDisplay from 'src/models/StatsDisplay';

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {

  public stats:StatsDisplay = {
    letters_processed: 0,
    emails_sent: 0,
    duplicates: 0,
    items_reviewed: 0,
    deleted_items: 0,
    amount_of_pages:0
  };

  // for last seen ID key
  private lastSeenId:any = null;
  private lastSeenDate:any = null;

  public isDropdownClicked: boolean = false;

  public isTimeFrameDisabled: boolean = false;
  public hasBeenManuallyDisabled: boolean = false;
  public customInputValue: string = "";
  
  
  public searchParams: SearchParams = {};
  public isLoading:boolean = false;
  public data:any[];
  public customerId:string = this.rootScope.globals.currentCustomer;
  timeFrames = [
    { label: 'Last Hour', value: 'hour' },
    { label: 'Last 3 Hours', value: 'three_hours' },
    { label: 'Last Day', value: 'day' },
    { label: 'Last Week', value: 'week' },
    { label: 'Advanced Search', value: 'advanced' }
  ];
  
  selectedTimeFrame:string;

  constructor(private apiService:ApiService, public rootScope: RootScopeService) { }

  async ngOnInit(): Promise<void> {
    this.selectedTimeFrame = 'hour'
    this.searchParams.start_date = this.getTimeAgo('hour')
    this.searchParams.end_date = this.getTimeNow()

    // default is last hour
    this.updateStats('hour')

  }
  /* selectCustomer(event:any) {
    this.searchParams.start_date = this.getTimeAgo('hour')
    this.searchParams.end_date = this.getTimeNow()
    this.updateStats('hour')
  } */
  async checkAdvanced() {
    this.lastSeenId = null;
    this.lastSeenDate = null;
    if (this.searchParams.start_date) this.searchParams.start_date = this.formatAdvancedRange(this.searchParams.start_date)
    if (this.searchParams.end_date) this.searchParams.end_date = this.formatAdvancedRange(this.searchParams.end_date)

    this.updateStats('advanced');
    // this.formatAdvancedRange(this.searchParams.start_date)
  }

  isRangeDateValid(start_date:any, end_date:any): boolean {
    let startDate = new Date(start_date);
    let endDate = new Date(end_date);

    return endDate > startDate;
}


  isValidRange():boolean {
    if (this.searchParams.start_date != undefined && this.isRangeDateValid(this.searchParams.start_date, this.searchParams.end_date)) {
      return true
    }
    return false;
  }

  formatAdvancedRange(dateTimeString: string|undefined): any {
    return dateTimeString?.replace('T', ' ');
  }

  toggleTimeFrame() {
    this.isTimeFrameDisabled = !this.isTimeFrameDisabled;
    this.isDropdownClicked = false; // Reset tracking, only change on actual click
  }

  toggleTimeFrameDropdown(): void {
    this.isTimeFrameDisabled = !this.isTimeFrameDisabled;
  }  
  
  handleDropdownClick() {
    if (this.isDropdownClicked) {
      this.selectedTimeFrame = "hour"; // Reset to default only if actually clicked
    }
    this.isDropdownClicked = true;
  }
  
  fetchBatchStats(upload_id: string) {
    this.apiService.getBatchStats(upload_id).subscribe({
      next: (stats) => {
        console.log('Batch Stats:', stats);
        this.populateBatchStats(stats['items'])
      },
      error: (err) => {
        console.error('Error fetching batch stats:', err);
      }
    });
  }


  async updateStats(timeframe:string) {
    this.isLoading = true
    await this.apiService.getEntries(this.lastSeenId, this.lastSeenDate, this.customerId ,this.searchParams).subscribe((data) => {
      this.populateStats(data.Items)
      // the 200 number is hardcoded as the scan max in Express. If we hit the maximum length, it means we need to scan more to return the correct stats
      if (data.LastEvaluatedKey && data.Items.length == 200) {
        this.lastSeenId = data.Items[data.Items.length-1].document_id
        this.lastSeenDate = data.Items[data.Items.length-1].date_of_upload
        this.updateStats(timeframe);
      }
      else {
        this.selectedTimeFrame = timeframe
        this.isLoading = false  
      }
    })
  }  

  private populateStats(data:any) {
    // reset the local array after each search, but only if we're not aggregating multiple DynamoDB scans
    if (this.lastSeenDate == null && this.lastSeenId == null) {
      this.stats.letters_processed = 0;
      this.stats.emails_sent = 0;
      this.stats.duplicates = 0;
      this.stats.items_reviewed = 0;
      this.stats.deleted_items = 0;  
      this.stats.amount_of_pages = 0
    }

    for (let i = 0; i < data.length; i++) {
      this.stats.letters_processed++;
      if (data[i].email_sent) {
        this.stats.emails_sent++
      }
      if (data[i].amount_of_pages) {
        this.stats.amount_of_pages += data[i].amount_of_pages;
      }
      if (data[i].duplicates.length > 1) {
        // only duplicates which are NOT original show up in the stat
        if (data[i].duplicates[data[i].duplicates.length - 1] != data[i].document_id) {
          this.stats.duplicates++;
        }
      }
      if (data[i].reviewed) {
        this.stats.items_reviewed++;
      }
      if (data[i]['deleted_flag'] == 1) {
        this.stats.deleted_items++;
      }
    }
  }

  private populateBatchStats(data: any[]) {
    // Initialize stats
    this.stats.letters_processed = data.length;
    this.stats.emails_sent = 0;
    this.stats.duplicates = 0;
    this.stats.items_reviewed = 0;
    this.stats.deleted_items = 0;
    this.stats.amount_of_pages = 0;
  
    for (const item of data) {
      if (item.original === true) {
        this.stats.duplicates += 1;
      }
      if (item.reviewed === true) {
        this.stats.items_reviewed += 1;
      }
      if (item.deleted === true) {
        this.stats.deleted_items += 1;
      }
      if ('amount_of_pages' in item) {
        this.stats.amount_of_pages += item['amount_of_pages'];
      }
    }
  }
  

  changeTimeFrame(event:any) {
    switch (event.value) {

      case 'hour':
        this.lastSeenDate = null; this.lastSeenId = null;
        this.searchParams.start_date = this.getTimeAgo('hour')
        this.searchParams.end_date = this.getTimeNow()
        this.updateStats('hour')
        break;
      
      case 'three_hours':  
        this.lastSeenDate = null; this.lastSeenId = null;
        this.searchParams.start_date = this.getTimeAgo('three_hours')
        this.searchParams.end_date = this.getTimeNow()
        this.updateStats('three_hours')
        break;

      case 'day':
        this.lastSeenDate = null; this.lastSeenId = null;
        this.searchParams.start_date = this.getTimeAgo('day')
        this.searchParams.end_date = this.getTimeNow()
        this.updateStats('day')
        break;

      case 'week':
        this.lastSeenDate = null; this.lastSeenId = null;
        this.searchParams.start_date = this.getTimeAgo('week')
        this.searchParams.end_date = this.getTimeNow()
        this.updateStats('week')
        break;

      case 'advanced':
    }
  }

  disableTimeFrameOnInputClick() {
    if (!this.isTimeFrameDisabled) {
      this.isTimeFrameDisabled = true;
      this.selectedTimeFrame = '-';
      this.hasBeenManuallyDisabled = true;
    }
  }
  
  submitCustomInput() {
    this.apiService.getBatchStats(this.customInputValue).subscribe({
      next: (data) => {
        console.log('Batch stats result:', data);
        this.populateBatchStats(data['items'])
        // Optional: this.batchStats = data;
      },
      error: (err) => {
        console.error('Error fetching batch stats:', err);
        // this.snackBar.open('Failed to load batch stats', 'Close', {
        //   duration: 3000,
        // });
      }
    });
  }  

  disableTimeFrameDropdown() {

    console.log("KRINJ")
    if (!this.isTimeFrameDisabled) {
      this.isTimeFrameDisabled = true;
      this.selectedTimeFrame = '-'; // Optional placeholder
      this.hasBeenManuallyDisabled = true;
    }
  
      // 🔥 Call the API with the input value
      this.apiService.getBatchStats(this.customInputValue).subscribe({
        next: (data) => {
          console.log('Batch stats result:', data);
          this.populateBatchStats(data['items'])
          // You can also display it or store it, e.g.
          // this.batchStats = data;
        },
        error: (err) => {
          console.error('Error fetching batch stats:', err);
          // this.snackBar.open('Failed to load batch stats', 'Close', {
          //   duration: 3000,
          // });
        }
      });
    
  }
    
  handleTimeFrameClick() {
    // Only re-enable if it was disabled via the text input
    if (this.hasBeenManuallyDisabled) {
      this.isTimeFrameDisabled = false;
      this.hasBeenManuallyDisabled = false;
      this.customInputValue = "";
    }
  }  


  getTimeAgo(timeframe: string): string {
    const now = new Date();
    
    switch(timeframe) {
      case 'hour':
        now.setUTCHours(now.getUTCHours() - 1);
        break;
      case 'three_hours':
        now.setUTCHours(now.getUTCHours() - 3);
        break;
      case 'day':
        now.setUTCDate(now.getUTCDate() - 1);
        break;
      case 'week':
        now.setUTCDate(now.getUTCDate() - 7);
        break;
      default:
        throw new Error('Invalid timeframe');
    }
  
    // Formatting the date to the desired format "YYYY-MM-DD HH:mm:ss"
    const formattedDate = `${now.getUTCFullYear()}-${String(now.getUTCMonth() + 1).padStart(2, '0')}-${String(now.getUTCDate()).padStart(2, '0')} ${String(now.getUTCHours()).padStart(2, '0')}:${String(now.getUTCMinutes()).padStart(2, '0')}:${String(now.getUTCSeconds()).padStart(2, '0')}`;
  
    return formattedDate;
  }


  getTimeNow(): string {
    const now = new Date();
    
    // Formatting the date to the desired format "YYYY-MM-DD HH:mm:ss"
    const formattedDate = `${now.getUTCFullYear()}-${String(now.getUTCMonth() + 1).padStart(2, '0')}-${String(now.getUTCDate()).padStart(2, '0')} ${String(now.getUTCHours()).padStart(2, '0')}:${String(now.getUTCMinutes()).padStart(2, '0')}:${String(now.getUTCSeconds()).padStart(2, '0')}`;
  
    return formattedDate;
  }  
  

}