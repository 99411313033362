import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { ApiService } from 'src/api.service';
import SearchParams from '../../models/SearchParams';
import { OktaAuth } from '@okta/okta-auth-js';
import { OKTA_AUTH } from '@okta/okta-angular';
import UserAction from 'src/models/UserAction';
import { DateTimeService } from '../shared/services/datetime.service';

@Component({
  selector: 'app-delete-batch',
  templateUrl: './delete-batch.component.html',
  styleUrls: ['./delete-batch.component.css']
})
export class DeleteBatchComponent implements OnInit {

  @Output() notifyParent: EventEmitter<any> = new EventEmitter();

  public isLoading:Boolean = false
  constructor(     
    @Inject(MAT_DIALOG_DATA) public data: any, 
    public dialogRef: MatDialogRef<DeleteBatchComponent>,
    private apiService: ApiService,
    private dateTimeService: DateTimeService,
    @Inject(OKTA_AUTH) private _oktaAuth: OktaAuth
  ) { }

  public deleteParams = <SearchParams>{};

  

  ngOnInit(): void {
    console.log(this.data)
  }

  public closeModal() {
    this.dialogRef.close()
  }


  public async deleteBatch() {
    this.isLoading = true;
    const loggedinUser = await this._oktaAuth.getUser();

    let userAction:UserAction = {
      user: loggedinUser.name!,
      date_of_action: this.dateTimeService.getCurrentDateTime(),
      action: "Deleted",
      date_of_upload: this.data.date_of_upload,
      document_id: this.data.document_id,
      customer_cd: this.data.customer_cd,
      item:'Batch',
      batch_id: this.data.batch_identifier
    }

    this.apiService.deleteBatch(this.data.batch_identifier, this.data.batch_file_name, this.data.date_of_upload).subscribe({
      next: (response) => {
          console.log('Batch deleted successfully:', response);
          this.isLoading = false;
          this.notifyParent.emit(response?.body)
          this.dialogRef.close()

          if ('uploadID' in response) {
            userAction.upload_id = response.uploadID;
          }
          
          console.log(userAction)

          this.apiService.logUserAction(userAction).subscribe((data) => {
            console.log("action logged")
          })  
    
      },
      error: (error) => {
          console.error('Error deleting batch:', error);
      }
  });

    const someData = { message: "This is a different action" };
    this.notifyParent.emit(someData);
    return 'someData'
  }
  
}