<!-- <div class="header" *ngIf="isDataLoaded">
  <img class="logo" src="assets/verisk_logo.svg">
</div> -->
<app-header></app-header>

<!-- <app-customers class="customers" [ngStyle]="{'position': isShowingDuplicates || (viewBatchDocument && !isBatches && !isNoResults) ? 'relative' : 'absolute'}" [customerId]="customerId" (customerIdChange)="customerId = $event" (subscriptionEmitter)="selectCustomer($event)"></app-customers> -->

<!-- BATCH MODE -->
<div class="main-content">
  <div *ngIf="isSearchTriggered && !isBatches" style="    background-color: #ebeff1;
  width: 100vw;
  max-width: 100%;
  margin-top: 100px;
  text-align: center;
  float: center;
">
  <!-- BATCH MODE SEARCH FORMS -->
    <mat-form-field appearance="fill" style="width: 40%;">
      <mat-label>Letter ID</mat-label>
      <mat-select [(ngModel)]="searchParams.letter_id">
        <mat-option class="search-options">
          <ngx-mat-select-search [(ngModel)]="search_field_letter" (input)="filterOptione($event.target)" placeholderLabel="Search Letter..."></ngx-mat-select-search>
        </mat-option>    
        <mat-option>All Letter Types</mat-option>
        <mat-option  *ngFor="let letter of letters_array" value={{letter}}>{{ letter }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  

    <form class="example-form" autocomplete="off">
      <mat-form-field style="width: 40%;" appearance="fill">
        <mat-label>Claim Number</mat-label>
        <input [(ngModel)]="searchParams.claim_number" name="first" matInput>
      </mat-form-field>
    </form>  

    <form class="example-form" autocomplete="off">
      <mat-form-field style="width: 40%;" class="example-full-width" appearance="fill">
        <mat-label>Beneficiary Name</mat-label>
        <input [(ngModel)]="searchParams.first_name" name="first" matInput>
      </mat-form-field>
    </form>  

    <!-- <form class="example-form" autocomplete="off">
      <mat-form-field style="width: 40%;" class="example-full-width" appearance="fill">
        <mat-label>Last Name</mat-label>
        <input [(ngModel)]="searchParams.last_name" name="first" matInput>
      </mat-form-field>
    </form>  --> 

    <mat-form-field appearance="fill" style="width: 40%;">
      <mat-label>Date of Letter</mat-label>
      <input [(ngModel)]="date_of_letter" matInput [matDatepicker]="dol" name="first">
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="dol"></mat-datepicker-toggle>
      <mat-datepicker #dol></mat-datepicker>
    </mat-form-field>
    
    <br>
    <br>

    <mat-form-field appearance="fill" style="width: 40%;">
      <mat-label>Date of Upload</mat-label>
      <input [(ngModel)]="date_of_upload" matInput [matDatepicker]="doi" name="first">
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="doi"></mat-datepicker-toggle>
      <mat-datepicker #doi></mat-datepicker>
    </mat-form-field>
    
    <br>
    <br>

    <br>
    <div style="padding-top:10px">
      <button class="search-buttons clear-button" (click)="clearSearch()">Collapse</button>
      <button (click)="search()" class="search-buttons search-button">Search</button>
    </div>
  
  
  </div>

  <!-- BATCH MODE CONTENT -->
  <div *ngIf="isSearchTriggered && isBatches" style="    background-color: #ebeff1;
  width: 100vw;
  max-width: 100%;
  margin-top: 100px;
  text-align: center;
  float: center;
">

    <form class="example-form" autocomplete="off">
      <mat-form-field style="width: 40%;" class="example-full-width" appearance="fill">
        <mat-label>File Name</mat-label>
        <input [(ngModel)]="batch_file_name" name="first" matInput>
      </mat-form-field>
    </form> 

    <mat-form-field appearance="fill" style="width: 40%;">
      <mat-label>Reviewed</mat-label>
      <mat-select [(ngModel)]="true_or_false">
        <mat-option class="search-options">
          <ngx-mat-select-search [(ngModel)]="search_field_letter" (input)="filterOptione($event.target)" placeholderLabel="Reviewed or Not Reviewed..."></ngx-mat-select-search>
        </mat-option>
        <mat-option>All</mat-option>     
        <mat-option  *ngFor="let letter of ['true', 'false']" value={{letter}}>{{ letter.toString().charAt(0).toUpperCase() + letter.toString().slice(1) }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    
    <br>

    <mat-form-field appearance="fill" style="width: 40%;">
      <mat-label>Date of Upload</mat-label>
      <input [(ngModel)]="batch_upload_date" matInput [matDatepicker]="doi" name="first" autocomplete="off">
      <mat-hint>MM/DD/YYYY</mat-hint>
      <mat-datepicker-toggle matSuffix [for]="doi"></mat-datepicker-toggle>
      <mat-datepicker #doi></mat-datepicker>
    </mat-form-field>

    
    <br>

    <br>
    <div style="padding-top:10px">
      <button class="search-buttons clear-button" (click)="clearSearch()">Collapse</button><button (click)="parseBatches()" class="search-buttons search-button">Search</button>
    </div>
  
  
  </div>

  <!-- LOADING ICON -->
  <div *ngIf="isLoading" class="loading">
    <img src="assets/load_gif.gif">
  </div>

  <!-- NO RESULTS PAGE -->
  <div *ngIf="isNoResults">
    <div class="no-results-wrapper">
      <img class="magnifying-glass" src="assets/magnifying-glass.png">
      <br>
      <h1>No Data to Display</h1>
      <br>
      <p style="color: grey;">Try adjusting the search criteria!</p>
      <br><br>
      <button class="no-results-reset" (click)="resetSearch()">Reset Search</button>
    </div>
  </div>

    <!-- NO BATCHES RESULTS PAGE -->
  <div *ngIf="isNoBatchResults">
    <div class="no-results-wrapper">
      <img class="magnifying-glass" src="assets/magnifying-glass.png">
      <br>
      <h1>No Batches Found</h1>
      <br>
      <p style="color: grey;">Try adjusting the search criteria!</p>
      <br><br>
      <button class="no-results-reset" (click)="resetBatchResults()">Reset Search</button>
    </div>
  </div>
  

  <!-- DOCUMENT MODE CONTENT -->
<div *ngIf="!isBatches && !isNoResults">
<div class="tile-container" id="tile-container">
    <div *ngFor="let entry of response?.Items; let i = index">
      <div *ngIf="i == 0" class="search-and-paginate">
        <span style="cursor: pointer;" (click)="triggerSearch()"><mat-icon class="mr-3" [ngStyle]="isSearchTriggered && {'display': 'none'}">search</mat-icon></span>
        <span style="float:right;" class="ml-3">
          <label class="switch">
            <input type="checkbox">
            <span (click)="toggle()" class="slider" ngbTooltip="Switch to batch files view" placement="bottom"></span>
          </label>  
          <span *ngIf="isSearchPopulated || searchParams.batch_id != undefined || isShowingDuplicates" style="margin-left: 20px; position:absolute; white-space: nowrap;"><button class="reset-search-button" (click)="resetSearch()">Reset Search</button></span>      
          <span *ngIf="isShowingDuplicates" style="margin-left: 20px; position:absolute; white-space: nowrap;"><button class="reset-search-button" (click)="resetSearch()">Reset Search</button></span>      
          <span *ngIf="searchParams.batch_id != undefined" style="margin-left: 120px; position:absolute; white-space: nowrap;">Results from {{entry.batch_file_name}}</span>  
          <span *ngIf="isShowingDuplicates" style="margin-left: 120px; position:absolute; white-space: nowrap;">Showing All Copies of Letter</span>
        </span>
        <span class="material-symbols-outlined" [ngClass]="lastPage != true ? 'nav-icons' : 'nav-icons-unclickable'" (click)="nextPage()">chevron_right</span>
        <span class="material-symbols-outlined" [ngClass]="firstPage != true ? 'nav-icons' : 'nav-icons-unclickable'" (click)="previousPage()">chevron_left</span>  
      </div>
      <div *ngIf="i < 24" class="tile-item" (click)="openPdf(entry.document_id, entry.letter_id)">
      <span class="date-of-letter" >
        <p ngbTooltip="Date of letter" placement="bottom" class="date-text p-tag">{{ entry.date_of_letter }}</p>

        <span>
          <span *ngIf="checkedOrNot(entry)" class="menu" (click)="review($event, entry)" ngbTooltip="Review" placement="bottom">
            <input type="checkbox" checked>
          </span>
          <span *ngIf="!checkedOrNot(entry)"  class="menu" (click)="review($event, entry)" ngbTooltip="Review" placement="bottom">
            <input type="checkbox" >
          </span>
        </span>

      </span>
      <div class="basic-info">
        <p class="name p-tag"> {{ entry.beneficiary_name }} </p>
        <p class="medicare-id p-tag" *ngIf="entry.medicare_id">Medicare ID: {{ entry.medicare_id }}</p>
        <p class="medicare-id p-tag" *ngIf="entry.claim_number">Claim: {{ entry.claim_number }}</p>
        <p class="claim-id p-tag" *ngIf="!entry.claim_number || !entry.medicare_id">{{ displayDataPoint(entry) }}</p>
        <p *ngIf="entry.letter_type && entry.letter_type.length <= 25" class="letter-type p-tag">Letter Type: {{ entry.letter_type }}</p>
        <p *ngIf="entry.letter_type && entry.letter_type.length >25" class="letter-type p-tag"  [ngbTooltip]="entry.letter_type" >Letter Type: {{ entry.letter_type.substring(0,20) }}...</p>
      </div>

      <div class="additional-info">
        <span class="page-count" style="background-color: #047CB1;">{{ batchFileDisplay(entry.batch_file_name?.split('/')?.slice(-1)[0]) }}</span>
        <!--<span class="letter-id" [ngStyle]="{'background-color': backgroundColor(entry.letter_id)}">{{ entry.letter_id }}</span>-->
        <span style="padding-right: 7px;"></span>
        <span [ngClass]="isDuplicateOrDeleted(entry) ? 'duplicate-display' : 'no-duplicate-display'">{{ displayDuplicateOrDeleted(entry) }}</span>
        <span style="padding-right: 7px;"></span>
        

      </div>
      <span class="page-count">{{ entry.amount_of_pages }} pages</span>
      <span style="padding-right: 7px;"></span>
      <span class="page-count" [ngStyle]="{'background-color': reviewedBackgroundColor(entry.reviewed)}">{{ entry.reviewed ? 'Reviewed' : 'Not Reviewed' }}</span>
      <span *ngIf="entry.original">
        <span style="padding-right: 7px;"></span>
        <span class="page-count" style="background-color: #AE7C85;">Original</span>  
      </span>
      <span *ngIf="entry.ie_status==='Manual Entry'" >
        <span style="padding-right: 7px;"></span>
        <button class="delete-button" style="display:inline-block; vertical-align: middle;" (click)="navigateToManualReview($event,entry)" placement="bottom"  ngbTooltip="Manual Review" ><mat-icon  class="align-top" style="height: 24px; transform: scale(.8); color:#044DD7">edit</mat-icon></button>
      </span>
      <div>
        
      </div>

      <div class="action">
        <span><p class="uploaded-text p-tag"><span class="uploaded-label" style="float: left;">Uploaded</span>
          <br>
          <span class="uploaded-content">{{ convertDate(entry.date_of_upload) }}</span>
        </p> 
      <span style="float: right; margin-right: 20px">
        <button class="delete-button" *ngIf="!entry.deleted" ngbTooltip="Delete" placement="bottom" (click)="deleteEntry($event, entry.document_id, entry.letter_id, entry.beneficiary_name, entry.claim_number, entry.duplicates, entry.date_of_upload, entry.batch_id, entry.batch_file_name, entry)">  <img class="align-top" src="assets/red-x.svg" style="height: 24px; transform: scale(.5)" aria-hidden="true"></button> 
        <span style="padding-left: 5px;"></span>
        <button (click)="viewDetails($event, entry.document_id)" class="delete-button" ngbTooltip="View details" placement="bottom"><mat-icon class="align-top" style="transform: scale(0.85); height: 24px;color: black;">visibility</mat-icon></button> 
        <span style="padding-left: 5px;"></span>
        <button class="delete-button" (click)="checkDuplicates($event, entry)" ngbTooltip="Duplicates" placement="bottom"><mat-icon class="align-top" style="height: 24px; transform: scale(.8); color:#044DD7">control_point_duplicate</mat-icon></button> </span>
        </span>
      </div>
    </div>
    </div>
  </div>
</div>
<div *ngIf="isBatches">
  <div class="tile-container" id="tile-container">
    <div *ngFor="let entry of response.Items; let i = index">
      <div *ngIf="i == 0" class="search-and-paginate">
        <span style="cursor: pointer;" (click)="triggerSearch()">
          <mat-icon class="mr-3" [ngStyle]="isSearchTriggered && {'display': 'none'}">search</mat-icon>
        </span>
        <span style="float:right;" class="ml-3">
          <label class="switch">
            <input type="checkbox" checked>
            <span (click)="toggle()" class="slider" ngbTooltip="Switch to split files view" placement="bottom">
              <span style="margin-left: 50px; position:absolute; white-space: nowrap;"></span>
            </span>
          </label>
          <span *ngIf="isBatchSearchPopulated_" style="margin-left: 20px; position:absolute; white-space: nowrap;">
            <button (click)="resetBatchResults()" class="reset-search-button">Reset Search</button>
          </span>
        </span>
        <span class="material-symbols-outlined" [ngClass]="lastPage != true ? 'nav-icons' : 'nav-icons-unclickable'" (click)="nextPageBatches()">chevron_right</span>
        <span class="material-symbols-outlined" [ngClass]="firstPage != true ? 'nav-icons' : 'nav-icons-unclickable'" (click)="previousPageBatches()">chevron_left</span>  
      </div>
      <div *ngIf="i < 24" class="tile-item" style="height: 203px; position: relative;">
        <mat-icon class="delete-icon" (click)="deleteBatch(entry)" 
                  matTooltip="Delete Batch" style="position: absolute; top: 5px; right: 5px; cursor: pointer; color: #D32F2F;">
          delete
        </mat-icon>
        
        <span class="date-of-letter">
          <p class="date-text" style="font-size: 13px;">{{ convertDate(entry.date_of_upload) }}</p>
        </span>
        <div class="basic-info">
          <p class="name p-tag-list" style="font-size: 16px;">{{ displayBatchName(entry.batch_file_name?.split('/').slice(-1)[0]) }}</p>
          <p class="medicare-id p-tag-list">{{ documentTotalDisplay(entry) }}</p>
          <p class="claim-id p-tag-list">{{ reviewedDocumentsDisplay(entry) }}</p>
        </div>

        <div>
          <p *ngIf="!isFullyReviewed(entry)" class="review-status p-tag-list">Not Fully Reviewed</p>
          <p *ngIf="isFullyReviewed(entry)" class="review-status p-tag-list" style="background-color: #1AA273;">Fully Reviewed</p>
          <div style="margin-top: 12px;"></div>
          <p class="view-documents p-tag-list" (click)="viewDocumentsinBatch(entry)">Click to View Documents</p>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
<app-footer></app-footer>
<!-- <div style="position:fixed; bottom: 0; width: 100vw; max-width: 100%; background-color: #00358E; color: white;font-family: 'Roboto', sans-serif; padding-top: 5px; padding-bottom: 7px; font-size: 12px;">
  <span><span>Relay Navigator</span><span style="position:absolute; left: 45%;">©2022 Verisk Analytics Inc.</span></span>
</div> -->
