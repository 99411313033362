<div style="width: 100%; height: 100%; background-color: white;">
    <!-- <p>HELLO STRETFORD END, HERE I AM!</p>
    <button (click)="deleteEntry()">HELLO</button> -->
    <img src="assets/circle-with-slash.png" style="width: 27%; margin-left: 38%; margin-top: 45px;">
    <p style="margin-top: 28px; font-size:30px; text-align: center" class="sure" >Are you sure?</p>
    <p style="margin-top: 30px; font-size:14px; text-align: center; margin-bottom: 50px;" class="sure">Are you sure you want to delete this batch?</p>
    <span class="buttons">
        <button (click)="closeModal()" class="modal-button" style="background-color: #C0C0C0;">Cancel</button>
        <span style="margin-left: 12px;"></span>
        <button (click)="deleteBatch()" class="modal-button" style="background-color: #EE3932;">Delete</button>    
    </span>
</div>